<template>
  <div>
    <column-list :set="false" :oData="oData" @changePage="handleChangePage"></column-list>
  </div>
</template>
<script>
import ColumnList from "../../teams/components/Columnlist";
import { specialGatherBoughtRequest } from "@/api/project.js";
export default {
  components: {
    ColumnList
  },
  data() {
    return {
      oData: ""
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(val) {
      try {
        val = val || "1";
        const result = await specialGatherBoughtRequest({
          page: val
        });
        if (result.status == 200) {
          this.oData = result.data;
        }
      } catch (err) {}
    },
    handleChangePage(val) {
      this.getList(val);
    }
  }
};
</script>
<style  lang="less" scoped>
</style>